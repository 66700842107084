.video {
    width: 100%;
    border: 1px solid black;
}

.videoplayer_player {
    display: table;
    width: 100%;
    position: relative;
}

.playpause {
    position: absolute;
    right: 10%;
    bottom: 30%;
}

.pineappleContainer {
    width: 100%;
    align-items: center;
    justify-content: center;
}

.introButton {
    background-color: rgba(43,51,63,.7);
}

.introButton:hover {
    background-color: #ffffff;
    color:#000000 
}

.profile1 {
    font-size: 90vmin;
    height: 150px;
    margin: 5vmin auto;
    width: 150px;
    position: relative;
}

    .profile1 div {
        position: absolute;
    }

    .profile1 #left-brow {
        transform-origin: center 80%;
        animation: raise 10s ease-in-out infinite;
    }

    .profile1 #sunglasses {
        transform-origin: center 100%;
        animation: tilt 10s 250ms ease infinite;
    }

    .profile1 #mustache {
        transform-origin: center 90%;
        animation: mustache 10s 0ms ease infinite;
    }

@keyframes raise {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    10% {
        transform: translateY(-10px) rotate(5deg);
    }

    20% {
        transform: translateY(-10px) rotate(5deg);
    }

    25% {
        transform: translateY(0px) rotate(0deg);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes tilt {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    10% {
        transform: translateY(-2px) rotate(5deg);
    }

    20% {
        transform: translateY(-2px) rotate(5deg);
    }

    25% {
        transform: translateY(0px) rotate(0deg);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes mustache {
    20% {
        transform: translateY(0px) scale(1);
    }

    30% {
        transform: translateY(4px) rotate(-3deg) scale(1.03);
    }

    40% {
        transform: translateY(0px) scale(1);
    }
}

.profile2 #left-pupil, .profile2 #right-pupil {
    animation: side-to-side 10s ease-in-out infinite alternate;
}

.profile2 #left-eye-top, .profile2 #right-eye-top {
    transform-origin: center bottom;
    animation: wide-open 10s ease-in-out infinite alternate;
}

.profile2 #bottom-beak, .profile2 #gillard {
    transform-origin: center top;
    animation: chew 20s ease-in-out infinite;
}

.profile2 #hair {
    animation: hair 4s ease infinite alternate;
}

@keyframes side-to-side {
    0% {
        transform: translateX(0px);
    }

    5% {
        transform: translateX(-5px);
    }

    10% {
        transform: translateX(-5px);
    }

    20% {
        transform: translateX(5px);
    }

    25% {
        transform: translateX(5px);
    }

    35% {
        transform: translateX(-5px);
    }

    40% {
        transform: translateX(-5px);
    }

    45% {
        transform: translateX(0px);
    }
}

@keyframes wide-open {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: scale(1,0);
    }

    42% {
        opacity: 1;
        transform: scale(1,1);
    }

    100% {
        opacity: 1;
        transform: scale(1,1);
    }
}

@keyframes chew {
    0% {
        transform: translateY(0px) rotate(0deg);
    }

    2% {
        transform: translateY(3px) rotate(-3deg);
    }

    4% {
        transform: translateY(0px) rotate(0deg);
    }

    6% {
        transform: translateY(3px) rotate(-3deg);
    }

    8% {
        transform: translateY(0px) rotate(0deg);
    }

    10% {
        transform: translateY(3px) rotate(-3deg);
    }

    12% {
        transform: translateY(0px) rotate(0deg);
    }

    14% {
        transform: translateY(3px) rotate(0deg);
    }

    18% {
        transform: translateY(0px) rotate(0deg);
    }

    26% {
        transform: translateY(15px);
    }

    78% {
        transform: translateY(15px);
    }

    80% {
        transform: translateY(0px) rotate(0deg);
    }

    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

@keyframes hair {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1,0.8);
    }

    100% {
        transform: scale(1);
    }
}

.profile3 svg {
    animation: bg-fade 10s ease infinite;
}

    .profile3 svg #smile {
        width: 100px;
    }

@keyframes bg-fade {
    0% {
        background: #86a546;
    }

    40% {
        background: #86a546;
    }

    45% {
        background: #e09927;
    }

    55% {
        background: #e09927;
    }

    60% {
        background: #86a546;
    }
}

.pineapple {
    font-size: 90vmin;
    height: 100%;
    width: 100%;
    position: relative;
}

    .pineapple div {
        position: absolute;
    }

.leaf {
    background-color: darkgreen;
    border-radius: 90% 0 90% 0;
    height: 20%;
    top: 10%;
    width: 20%;
}

    .leaf.left {
        animation: leafLeft 0.25s ease-in-out alternate infinite;
        left: 50%;
        transform-origin: bottom left;
    }

    .leaf.middle {
        animation: leafMiddle 0.25s ease-in-out alternate infinite;
        background-color: green;
        left: 40%;
        top: 5%;
        transform: rotate(-40deg);
    }

    .leaf.right {
        animation: leafRight 0.25s ease-in-out alternate infinite;
        left: 50%;
        transform-origin: bottom left;
    }

.body {
    animation: body 0.25s ease-in-out alternate infinite;
    background-color: #ffa700;
    border-radius: 40%;
    height: 50%;
    left: 24%;
    top: 27%;
    width: 54%;
}

    .body::after {
        background: repeating-linear-gradient(-45deg,transparent,transparent 15%,darkorange 15%,darkorange 20%), repeating-linear-gradient(45deg,transparent,transparent 15%,darkorange 15%,darkorange 20%);
        border-radius: 40%;
        content: "";
        height: 100%;
        position: absolute;
        left: 0px;
        width: 100%;
    }

.eye {
    animation: eye 1.04s infinite;
    background-color: black;
    border-radius: 100%;
    height: 15%;
    top: 35%;
    width: 15%;
    z-index: 2;
}

    .eye.left {
        left: 25%;
    }

    .eye.right {
        right: 25%;
    }

.mouth {
    background-color: black;
    border-radius: 20% 20% 80% 80%;
    height: 12%;
    left: 41%;
    top: 50%;
    width: 18%;
    z-index: 2;
}

.arm {
    background-color: #ffa700;
    border-radius: 100vw;
    height: 50%;
    top: 20%;
    width: 20%;
}

    .arm.left {
        animation: armLeft 0.26s ease-in-out alternate infinite;
        left: 10%;
        transform-origin: bottom left;
    }

    .arm.right {
        animation: armRight 0.26s ease-in-out alternate infinite;
        right: 10%;
        transform-origin: bottom right;
    }

.leg {
    animation: foot 0.26s alternate 0.13s infinite;
    background: #ffa700;
    border-radius: 100vw;
    bottom: -20%;
    height: 40%;
    width: 20%;
}

    .leg.left {
        left: 20%;
    }

    .leg.right {
        right: 20%;
    }

.shadow {
    animation: shadow 0.26s ease alternate infinite;
    background-color: tan;
    border-radius: 100%;
    bottom: 5%;
    height: 10%;
    left: 28%;
    opacity: 0.5;
    width: 46%;
}

@keyframes leafLeft {
    from {
        transform: rotate(-75deg);
    }

    to {
        transform: rotate(-85deg);
    }
}

@keyframes leafMiddle {
    from {
        transform: translateY(-10%) rotate(-40deg);
    }

    to {
        transform: translateY(10%) rotate(-40deg);
    }
}

@keyframes leafRight {
    from {
        transform: rotate(-5deg);
    }

    to {
        transform: rotate(5deg);
    }
}

@keyframes armLeft {
    from {
        transform: rotate(-40deg);
    }

    to {
        transform: rotate(-50deg);
    }
}

@keyframes armRight {
    from {
        transform: rotate(40deg);
    }

    to {
        transform: rotate(50deg);
    }
}

@keyframes body {
    from {
        transform: translateY(-2%);
    }

    to {
        transform: translateY(2%);
    }
}

@keyframes eye {
    100% {
        transform: rotateX(0deg);
    }

    50% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}

@keyframes foot {
    from {
        transform: translateY(-10%);
    }

    to {
        transform: translateY(10%);
    }
}

@keyframes shadow {
    from {
        transform: scaleX(1.1);
    }

    to {
        transform: scaleX(1);
    }
}